import React, { useState, useCallback, useEffect } from 'react';
import {
    Box,
    Button,
    Container,
    TextField,
    Typography,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Paper,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Pagination,
    Alert
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDropzone } from 'react-dropzone';
import { SelectChangeEvent } from '@mui/material/Select';
import BlogService, { CreateNewPostItemDto, PostItem } from "../../logic/BlogService";
import { CategoryType, CategoryTypeDescriptions } from "../../types/blog/categoryType";

const BlogComponent: React.FC = () => {
    const theme = useTheme();

    const [postItems, setPostItems] = useState<PostItem[]>([]);
    const [editingPost, setEditingPost] = useState<PostItem | null>(null);
    const [newTitle, setNewTitle] = useState('');
    const [newContent, setNewContent] = useState('');
    const [newImage, setNewImage] = useState<File | null>(null);
    const [category, setCategory] = useState<CategoryType | ''>('');
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<CategoryType | ''>('');
    const [currentPage, setCurrentPage] = useState(1);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [currentPhotoUrl, setCurrentPhotoUrl] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const itemsPerPage = 5;

    useEffect(() => {
        fetchPostItems();
    }, []);

    const fetchPostItems = async () => {
        try {
            const posts = await BlogService.getAllPosts();
            setPostItems(posts);
        } catch (error) {
            console.error('Error fetching post items', error);
        }
    };

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const file = acceptedFiles[0];
        setNewImage(file);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: { 'image/*': [] } });

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setEditingPost(null);
        setNewTitle('');
        setNewContent('');
        setNewImage(null);
        setCategory('');
        setCurrentPhotoUrl(null);
    };

    const handleCreateOrUpdatePost = async () => {
        setIsSubmitting(true);
        const currentDate = new Date().toISOString().split('T')[0];

        if (editingPost) {
            const updatedPost = {
                ...editingPost,
                title: newTitle,
                content: {
                    ...editingPost.content,
                    bodyContent: newContent,
                    photoObjectKey: newImage ? URL.createObjectURL(newImage) : editingPost.content.photoObjectKey
                },
                type: category as CategoryType,
                dateOfCreation: currentDate
            };

            try {
                await BlogService.updatePost(updatedPost, newImage || undefined);
                await fetchPostItems();
            } catch (error) {
                console.error('Failed to update post item', error);
                setErrorMessage('Не удалось добавить или обновить новость.');
            }
        } else {
            const postItem: CreateNewPostItemDto = {
                title: newTitle,
                bodyContent: newContent,
                image: newImage as File,
                category: category as CategoryType,
            };

            try {
                await BlogService.createNewPost(postItem);
                await fetchPostItems();
            } catch (error) {
                console.error('Failed to create or update post item', error);
                setErrorMessage('Не удалось добавить или обновить новость.');
            }
        }

        setIsSubmitting(false);
        setCurrentPhotoUrl(null); 
        handleCloseDialog();
    };

    const handleEditPost = (post: PostItem) => {
        setEditingPost(post);
        setNewTitle(post.title);
        setNewContent(post.content.bodyContent);
        setCategory(post.type);
        setCurrentPhotoUrl(post.content.photoObjectKey);
        handleOpenDialog();
    };

    const handleDeletePost = async (id: string) => {
        try {
            await BlogService.deletePostById(id);
            setPostItems(prevItems => prevItems.filter(item => item.id !== id));
        } catch (error) {
            console.error('Failed to delete post item', error);
        }
    };

    const handleCategoryChange = (event: SelectChangeEvent<string>) => {
        setSelectedCategory(event.target.value as CategoryType);
        setCurrentPage(1);
    };

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    };

    const filteredPosts = selectedCategory
        ? postItems.filter(post => post.type === selectedCategory)
        : postItems;

    const paginatedPosts = filteredPosts.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <Container>
            <Typography variant="h4" sx={{ fontWeight: 800, color: theme.palette.primary.main, marginBottom: '20px' }}>
                Управление новостями
            </Typography>
            {errorMessage && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {errorMessage}
                </Alert>
            )}
            <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleOpenDialog} sx={{ marginBottom: '20px', fontWeight: 800 }}>
                Добавить новость
            </Button>
            <Box display="flex" justifyContent="flex-end" sx={{ mb: 4 }}>
                <FormControl margin="normal" variant="standard" sx={{ minWidth: 200 }}>
                    <InputLabel id="filter-category-label" sx={{ color: theme.palette.primary.main }}>Фильтр по категории</InputLabel>
                    <Select
                        labelId="filter-category-label"
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                        label="Фильтр по категории"
                        sx={{
                            color: theme.palette.primary.main,
                            '& .MuiInput-underline:before': {
                                borderBottomColor: theme.palette.primary.main,
                            },
                            '& .MuiInput-underline:hover:before': {
                                borderBottomColor: theme.palette.secondary.main,
                            },
                            '& .MuiInput-underline:after': {
                                borderBottomColor: theme.palette.secondary.main,
                            }
                        }}
                    >
                        <MenuItem value="" sx={{ color: theme.palette.primary.main }}>Все категории</MenuItem>
                        {Object.values(CategoryType).map((type) => (
                            <MenuItem key={type} value={type} sx={{ color: theme.palette.primary.main }}>
                                {CategoryTypeDescriptions[type]}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="md">
                <DialogTitle sx={{ color: theme.palette.primary.main, fontWeight: 800 }}>{editingPost ? 'Редактировать новость' : 'Создать новость'}</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Заголовок"
                        value={newTitle}
                        onChange={(e) => setNewTitle(e.target.value)}
                        fullWidth
                        margin="normal"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: theme.palette.primary.main,
                                },
                                '&:hover fieldset': {
                                    borderColor: theme.palette.secondary.main,
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: theme.palette.secondary.main,
                                }
                            },
                            '& .MuiInputBase-input': {
                                color: theme.palette.primary.main,
                            },
                            '& .MuiInputLabel-root': {
                                color: theme.palette.primary.main,
                            }
                        }}
                    />
                    <ReactQuill theme="snow" value={newContent} onChange={setNewContent} style={{ marginBottom: '20px', color: 'black' }} />
                    <Box {...getRootProps()} sx={{
                        border: '2px dashed',
                        borderColor: theme.palette.primary.main,
                        borderRadius: '5px',
                        padding: '20px',
                        textAlign: 'center',
                        backgroundColor: isDragActive ? theme.palette.action.hover : 'transparent',
                        '&:hover': {
                            backgroundColor: theme.palette.action.hover,
                        },
                        mb: 2,
                        color: theme.palette.primary.main,
                    }}>
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <Typography variant="body1" sx={{ color: theme.palette.primary.main }}>Отпустите файлы, чтобы загрузить их...</Typography>
                        ) : (
                            <Typography variant="body1" sx={{ color: theme.palette.primary.main }}>Перетащите сюда фото или нажмите, чтобы выбрать файл</Typography>
                        )}
                    </Box>
                    {newImage ? (
                        <Paper variant="outlined" sx={{ mb: 2 }}>
                            <img src={URL.createObjectURL(newImage)} alt="Preview" style={{ width: '100%', maxHeight: '200px', objectFit: 'cover' }} />
                        </Paper>
                    ) : currentPhotoUrl ? (
                        <Paper variant="outlined" sx={{ mb: 2 }}>
                            <img src={currentPhotoUrl} alt="Current" style={{ width: '100%', maxHeight: '200px', objectFit: 'cover' }} />
                        </Paper>
                    ) : null}
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="category-label" sx={{ color: theme.palette.primary.main }}>Категория</InputLabel>
                        <Select
                            labelId="category-label"
                            value={category}
                            onChange={(e) => setCategory(e.target.value as CategoryType)}
                            label="Категория"
                            sx={{
                                color: theme.palette.primary.main,
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                    '&:hover fieldset': {
                                        borderColor: theme.palette.secondary.main,
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: theme.palette.secondary.main,
                                    }
                                }
                            }}
                        >
                            {Object.values(CategoryType).map((type) => (
                                <MenuItem key={type} value={type} sx={{ color: theme.palette.primary.main }}>
                                    {CategoryTypeDescriptions[type]}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="secondary" disabled={isSubmitting}>
                        Отмена
                    </Button>
                    <Button onClick={handleCreateOrUpdatePost} color="primary" variant="contained" sx={{ fontWeight: 800 }} disabled={isSubmitting}>
                        {editingPost ? 'Сохранить изменения' : 'Создать новость'}
                    </Button>
                </DialogActions>
            </Dialog>
            <Box>
                <Typography variant="h5" sx={{ fontWeight: 700, color: theme.palette.primary.main, marginBottom: '10px' }}>
                    Все новости
                </Typography>
                <List>
                    {paginatedPosts.map((post) => (
                        <ListItem key={post.id.toString()}>
                            <ListItemText primary={post.title} secondary={`${CategoryTypeDescriptions[post.type]} - ${post.dateOfCreation}`} sx={{ color: theme.palette.primary.main }} />
                            <IconButton onClick={() => handleEditPost(post)}>
                                <EditIcon />
                            </IconButton>
                            <IconButton onClick={() => handleDeletePost(post.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItem>
                    ))}
                </List>
                <Pagination
                    count={Math.ceil(filteredPosts.length / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    sx={{
                        mt: 2,
                        display: 'flex',
                        justifyContent: 'center',
                        '& .MuiPaginationItem-root': {
                            '&.Mui-selected': {
                                backgroundColor: theme.palette.secondary.main,
                                color: 'white',
                            },
                            '&:not(.Mui-selected)': {
                                backgroundColor: theme.palette.primary.main,
                            },
                        },
                    }}
                />
            </Box>
        </Container>
    );
};

export default BlogComponent;