import axios from 'axios';
import { apiUrl } from '../App';

interface ProcessLogInDTO {
    email: string;
    password: string;
}

class AuthService {
    async processLogIn(processLogInDTO: ProcessLogInDTO): Promise<boolean> {
        try {
            const response = await axios.post(`${apiUrl}/auth/basic/admin/login`, processLogInDTO);
            return response.status === 200;
        } catch (error) {
            console.error('Error while log in: ', error);
            return false
        }
    }

    async processLogOut(): Promise<boolean> {
        try {
            const response = await axios.post(`${apiUrl}/auth/cookie/logout`);
            return response.status === 204;
        } catch (error) {
            console.error('Error while log out: ', error);
            return false
        }
    }
}

export default new AuthService();