import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    IconButton, Typography
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import {CategoryChosen, Product} from "../../../types/products/interfaces";
import ProductAndCategoryService from "../../../logic/ProductAndCategoryService";

interface EditProductDialogProps {
    open: boolean;
    product: Product | null;
    onClose: () => void;
    onSave: (updatedProduct: Product) => void;
}

const EditProductDialog: React.FC<EditProductDialogProps> = ({ open, product, onClose, onSave }) => {
    const [name, setName] = useState(product?.name || '');
    const [price, setPrice] = useState(product?.price || 0);
    const [description, setDescription] = useState(product?.description || '');
    const [categories, setCategories] = useState<CategoryChosen[]>(product?.categories || []);
    const [allCategories, setAllCategories] = useState<{ [key: string]: string[] }>({});

    useEffect(() => {
        if (product) {
            setName(product.name || '');
            setPrice(product.price || 0);
            setDescription(product.description || '');
            setCategories(product.categories || []);
        }
    }, [product]);

    useEffect(() => {
        const fetchCategories = async () => {
            const fetchedCategories = await ProductAndCategoryService.getAllCategories();
            const categoryMap: { [key: string]: string[] } = {};
            fetchedCategories.forEach(cat => {
                categoryMap[cat.name] = cat.values || [];
            });
            setAllCategories(categoryMap);
        };

        fetchCategories();
    }, []);

    const handleSave = () => {
        if (product) {
            const updatedProduct: Product = {
                id: product.id,
                name,
                price,
                description,
                categories,
                mediaObjectKeys: product.mediaObjectKeys,
                positions: product.positions,
            };
            onSave(updatedProduct);
            onClose();
        }
    };

    const handleCategoryChange = (index: number, value: string) => {
        const newCategories = [...categories];
        newCategories[index].value = value;
        setCategories(newCategories);
    };

    const handleAddCategory = () => {
        setCategories([...categories, { name: '', value: '' }]);
    };

    const handleRemoveCategory = (index: number) => {
        const newCategories = categories.filter((_, idx) => idx !== index);
        setCategories(newCategories);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Изменить товар</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Название"
                    type="text"
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Цена"
                    type="number"
                    fullWidth
                    value={price}
                    onChange={(e) => setPrice(Number(e.target.value))}
                />
                <TextField
                    margin="dense"
                    label="Описание"
                    type="text"
                    fullWidth
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
                <Typography variant="h6" marginTop={2}>Категории</Typography>
                {categories.map((category, index) => (
                    <Box key={index} display="flex" alignItems="center" marginBottom={2}>
                        <FormControl fullWidth margin="dense" style={{ marginRight: 8 }}>
                            <InputLabel>{category.name}</InputLabel>
                            <Select
                                value={category.value || ''}
                                onChange={(e) => handleCategoryChange(index, e.target.value)}
                            >
                                {allCategories[category.name]?.includes(category.value) ? (
                                    <MenuItem value={category.value}>{category.value}</MenuItem>
                                ) : (
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                )}

                                {allCategories[category.name]?.map((value, idx) => (
                                    <MenuItem key={idx} value={value}>{value}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {/*<IconButton onClick={() => handleRemoveCategory(index)}>*/}
                        {/*    <Delete />*/}
                        {/*</IconButton>*/}
                    </Box>
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Отмена</Button>
                <Button onClick={handleSave} variant="contained" color="primary">
                    Сохранить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditProductDialog;