import React from 'react';
import {ListItem, ListItemIcon, ListItemText, Theme} from '@mui/material';
import {useNavigate} from "react-router-dom";
import {MenuItem} from "../../types/menuItems";

interface MenuItemProps extends MenuItem {
    active: boolean;
    theme: Theme;
}

const MenuItemComponent: React.FC<MenuItemProps> = ({ name, icon: Icon, path, active, theme }) => {
    const navigate = useNavigate()

    const handleClick = () => {
        navigate(path);
    };

    return (
        <ListItem button selected={active} onClick={handleClick}
                  sx={{
                      '&.Mui-selected': {
                          borderRight: active ? `4px solid ${theme.palette.secondary.main}` : 'none',
                          '&:hover': {
                              backgroundColor: 'rgba(255,105,180,0.7)',
                          },
                      },
                      '&:hover': {
                          backgroundColor: 'rgba(255,105,180,0.25)',
                      },
                  }}
        >
            <ListItemIcon>
                <Icon sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText
                primaryTypographyProps={{ sx: { fontWeight: 800, color: 'white' } }}
                primary={name}/>
        </ListItem>
    );
};

export default MenuItemComponent;