export enum CategoryType {
    SYSTEM = "SYSTEM",
    PROMO = "PROMO",
    INTERESTING = "INTERESTING"
}

export const CategoryTypeDescriptions: { [key in CategoryType]: string } = {
    [CategoryType.SYSTEM]: "Новости",
    [CategoryType.PROMO]: "Объявления",
    [CategoryType.INTERESTING]: "События"
};
