import React, { useState } from 'react';
import { Container, Typography, Button, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import * as XLSX from 'xlsx';
import ProductAndCategoryService from "../../logic/ProductAndCategoryService";
import { Product } from "../../types/products/interfaces";

const GenerateDocumentComponent: React.FC = () => {
    const theme = useTheme();
    const [generationType, setGenerationType] = useState<string>('stateOfAllProducts');

    // Функция для генерации Excel документа
    const generateExcel = async () => {
        try {
            // Получаем данные с сервера только при генерации документа
            const fetchedProducts = await ProductAndCategoryService.getAllProductsForAdmin();
            const productsWithStatus = fetchedProducts.map(product => ({
                ...product,
                positions: product.positions.map(position => ({
                    ...position,
                    status: position.status || "Not defined"
                }))
            }));

            // Подготовка данных для Excel
            let data: any[] = [];

            productsWithStatus.forEach(product => {
                // Добавляем информацию о продукте
                data.push({
                    "Product": product.name,
                    "Categories": product.categories.map(cat => `${cat.name}: ${cat.value}`).join(', '),
                    "Price": product.price,
                });

                // Добавляем пустую строку для разделения продукта и его позиций
                data.push({});

                // Добавляем позиции продукта
                product.positions.forEach(position => {
                    data.push({
                        "Position ID": position.positionId,
                        "Size": position.size,
                        "Position Status": position.status,
                    });
                });

                // Добавляем еще одну пустую строку для разделения между продуктами
                data.push({});
            });

            const worksheet = XLSX.utils.json_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Products");

            // Получаем текущую дату и время
            const now = new Date();
            const formattedDate = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}`;
            const formattedTime = `${now.getHours().toString().padStart(2, '0')}-${now.getMinutes().toString().padStart(2, '0')}-${now.getSeconds().toString().padStart(2, '0')}`;
            const fileName = `products_${formattedDate}_${formattedTime}.xlsx`;

            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Ошибка при генерации Excel файла:", error);
        }
    };

    const handleGenerateDocument = () => {
        if (generationType === 'stateOfAllProducts') {
            generateExcel();
        }
    };

    return (
        <Container>
            <Typography variant="h4" sx={{ fontWeight: 800, color: theme.palette.primary.main, marginBottom: '20px' }}>
                Генерация документов
            </Typography>

            <FormControl sx={{ marginBottom: '20px', minWidth: 200 }}>
                <InputLabel id="generation-type-label">Тип генерации</InputLabel>
                <Select
                    labelId="generation-type-label"
                    value={generationType}
                    onChange={(e) => setGenerationType(e.target.value as string)}
                >
                    <MenuItem value="stateOfAllProducts">Состояние всех продуктов</MenuItem>
                </Select>
            </FormControl>

            <Button variant="contained" color="primary" onClick={handleGenerateDocument}>
                Сгенерировать документ
            </Button>
        </Container>
    );
}

export default GenerateDocumentComponent;