import { createTheme } from '@mui/material/styles';
import {PaletteColorOptions} from "@mui/material";

declare module '@mui/material/styles' {
    interface Palette {
        critic: PaletteColor;
    }
    interface PaletteColor {
        main: string;
        second: string;
    }
    interface TypeBackground {
        alternate?: string;
    }
    interface PaletteOptions {
        critic?: PaletteColorOptions;
    }
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#3258a1',
        },
        secondary: {
            main: '#FF69B4',
        },
        error: {
            main: '#C84545',
        },
        background: {
            default: 'white',
            alternate: 'white',
        },
        critic: {
            main: '#62CD5D',
            light: '#507F9E'
        },
        text: {
            primary: '#000000',
            secondary: '#686868'
        }
    },
    typography: {
        fontFamily: 'Poppins, Arial, sans-serif',
        fontSize: 14
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white',
                },
            },
        },
    },
});

export default theme;