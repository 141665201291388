import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Typography, Container, Divider } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';

const ErrorPage: React.FC = () => {
    const theme = useTheme();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get('status') || 'error';

    let message: string;

    switch (status) {
        case '404':
            message = 'Страница не найдена';
            break;
        case '500':
            message = 'Внутренняя ошибка сервера';
            break;
        case '403':
            message = 'У вас нет доступа к этому ресурсу!';
            break;
        default:
            message = 'Произошла неожиданная ошибка';
    }

    const imageUrl = 'hand-drawn-no-data-illustration.png';
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Container maxWidth="md">
            <Box
                display="flex"
                flexDirection={isMobile ? 'column' : 'row'}
                alignItems="center"
                justifyContent="center"
                height="100vh"
                textAlign={isMobile ? 'center' : 'left'}
            >
                {imageUrl && (
                    <Box
                        component="img"
                        src={imageUrl}
                        alt="Error"
                        sx={{
                            maxWidth: isMobile ? '100%' : '50%',
                            marginBottom: isMobile ? '20px' : 0,
                        }}
                    />
                )}
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}>
                    {!isMobile && (
                        <Divider
                            orientation="vertical"
                            flexItem
                            sx={{
                                height: 'auto',
                                backgroundColor: theme.palette.secondary.main,
                                mx: 2,
                                width: '4px',
                                alignSelf: 'stretch'
                            }}
                        />
                    )}
                    <Box>
                        <Typography variant="h3" gutterBottom sx={{
                            color: theme.palette.primary.main,
                            fontWeight: 800,
                        }}>
                            Ошибка {status}
                        </Typography>
                        <Typography variant="h5" sx={{
                            color: theme.palette.text.secondary,
                        }}>{message}</Typography>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

export default ErrorPage;
