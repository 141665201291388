import React, {useState} from 'react';
import {Box, useTheme} from "@mui/material";
import useWindowWidth from "../hooks/useWindowWidth";
import LeftNavigationBar from "../components/navigation/LeftNavigationBar";
import TopNavigationBar from "../components/navigation/TopNavigationBar";
import ContentWrapper from "../components/wrapper/ContentWrapper";
import {Route, Routes} from "react-router-dom";
import BlogComponent from "../components/pages/BlogComponent";
import ProductComponent from "../components/pages/ProductComponent";
import PassportComponent from "../components/pages/PassportComponent";
import DocumentsComponent from "../components/pages/DocumentsComponent";
import PromoComponent from "../components/pages/PromoComponent";
import GenerateDocumentComponent from "../components/pages/GenerateDocumentComponent";
import OrderComponent from "../components/pages/OrderComponent";

export const leftNavBarWidth = 275;


const PanelPage: React.FC = () => {
    const theme = useTheme();

    const [open, setOpen] = useState(false);
    const { isMobile } = useWindowWidth();

    const handleLeftNavBarOpen = () => {
        setOpen(true);
    };

    const handleLeftNavBarClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <LeftNavigationBar width={leftNavBarWidth} open={open} onClose={handleLeftNavBarClose} isMobile={isMobile} />
            <Box sx={{ flexGrow: 1, width: `calc(100% - ${leftNavBarWidth}px)`, height: '100vh',
                overflow: 'hidden' }}>
                <TopNavigationBar drawerWidth={leftNavBarWidth} isMobile={isMobile} onDrawerOpen={handleLeftNavBarOpen} />
                <ContentWrapper>
                    <Routes>
                        <Route path="/blog" element={<BlogComponent />} />
                        <Route path="/products" element={<ProductComponent />} />
                        <Route path="/passport" element={<PassportComponent />} />
                        <Route path="/docs" element={<DocumentsComponent />} />
                        <Route path="/promo" element={<PromoComponent />} />
                        <Route path="/docs-gen" element={<GenerateDocumentComponent />} />
                        <Route path="/" element={<OrderComponent />} />
                    </Routes>
                </ContentWrapper>
            </Box>
        </Box>
    )
}

export default PanelPage;