import { useState, useEffect } from 'react';
import {useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/material/styles";

function useWindowWidth() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return { windowWidth, isMobile };
}

export default useWindowWidth;
