import axios from 'axios';
import { apiUrl } from '../App';
import {CategoryType} from "../types/blog/categoryType";

export interface CreateNewPostItemDto {
    title: string;
    bodyContent: string;
    image: File;
    category: CategoryType;
}

export interface PostItem {
    id: string;
    title: string;
    content: Content;
    type: CategoryType;
    dateOfCreation: string;
}

interface Content {
    bodyContent: string;
    photoObjectKey: string;
}

class BlogService {

    async createNewPost(createNewPostDto: CreateNewPostItemDto): Promise<void> {
        const formData = new FormData();
        formData.append('title', createNewPostDto.title);
        formData.append('bodyContent', createNewPostDto.bodyContent);
        formData.append('photo', createNewPostDto.image);
        formData.append('type', createNewPostDto.category);

        try {
            const response = await axios.post(`${apiUrl}/blog/post`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('News item created successfully', response.data);
        } catch (error) {
            console.error('Error creating news item', error);
            throw error;
        }
    }

    async updatePost(postItem: PostItem, image?: File): Promise<void> {
        const formData = new FormData();
        formData.append('id', postItem.id);
        formData.append('title', postItem.title);
        formData.append('bodyContent', postItem.content.bodyContent);
        formData.append('type', postItem.type);
        if (image) {
            formData.append('photo', image);
        }

        try {
            const response = await axios.put(`${apiUrl}/blog/post`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('News item updated successfully', response.data);
        } catch (error) {
            console.error('Error updating news item', error);
            throw error;
        }
    }

    async deletePostById(id: string): Promise<void> {
        try {
            await axios.delete(`${apiUrl}/blog/post/${id}`);
            console.log('News item deleted successfully');
        } catch (error) {
            console.error('Error deleting news item', error);
            throw error;
        }
    }

    async getAllPosts(): Promise<PostItem[]> {
        try {
            const response = await axios.get(`${apiUrl}/blog/posts`);
            return response.data;
        } catch (error) {
            console.error('Error fetching news items', error);
            throw error;
        }
    }
}

export default new BlogService();