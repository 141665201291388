import { LocalShipping as LocalShippingIcon, Newspaper as NewspaperIcon, Checkroom as CheckroomIcon, Badge as BadgeIcon, Article as ArticleIcon, Percent as PercentIcon, AdfScanner as AdfScannerIcon } from '@mui/icons-material';

export interface MenuItem {
    name: string;
    icon: React.ElementType;
    path: string;
}

export const menuItems: MenuItem[] = [
    { name: 'Заказы', icon: LocalShippingIcon, path: '/admin/panel' },
    { name: 'Блог', icon: NewspaperIcon, path: '/admin/panel/blog' },
    { name: 'Товары', icon: CheckroomIcon, path: '/admin/panel/products' },
    { name: 'Паспорта', icon: BadgeIcon, path: '/admin/panel/passport' },
    { name: 'Документы', icon: ArticleIcon, path: '/admin/panel/docs' },
    { name: 'Отчеты', icon: AdfScannerIcon, path: '/admin/panel/docs-gen' },
    { name: 'Пользователи и промокоды', icon: PercentIcon, path: '/admin/panel/promo' },
];