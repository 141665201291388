import React from 'react';
import { Box } from '@mui/material';

interface ContentWrapperProps {
    children: React.ReactNode;
}

const ContentWrapperArtist: React.FC<ContentWrapperProps> = ({ children }) => {

    return (
        <Box sx={{
            flexGrow: 1,
            px: 3,
            pt: 8,
            height: '100vh',
            overflow: 'auto',
        }}>
            {children}
        </Box>
    );
};

export default ContentWrapperArtist;