import React, { useEffect, useState } from 'react';
import { Alert, Button, Container, List, ListItem, ListItemText, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DocumentsService, { InfoAboutDocuments } from '../../logic/DocumentsService';

const DocumentsComponent: React.FC = () => {
    const theme = useTheme();
    const [documents, setDocuments] = useState<InfoAboutDocuments | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetchDocuments = async () => {
        try {
            const docs = await DocumentsService.getAllDocuments();
            setDocuments(docs);
            checkForNullDocuments(docs); // Проверяем на наличие null ссылок
        } catch (error) {
            setError('Ошибка загрузки документов. Пожалуйста, попробуйте позже.');
        }
    };

    useEffect(() => {
        fetchDocuments();
    }, []);

    const checkForNullDocuments = (docs: InfoAboutDocuments) => {
        const errors = [];
        if (!docs.privacyPolicyUrl) errors.push('Политика конфиденциальности не загружена.');
        if (!docs.termsOfServiceUrl) errors.push('Условия обслуживания не загружены.');
        if (!docs.userAgreementUrl) errors.push('Пользовательское соглашение не загружено.');
        if (!docs.dataProcessingPolicyUrl) errors.push('Политика обработки данных не загружена.');

        if (errors.length > 0) {
            setError(errors.join(' '));
        }
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
        if (event.target.files && event.target.files[0]) {
            try {
                const file = event.target.files[0];
                await DocumentsService.updateDocument(fieldName, file);
                await fetchDocuments();
            } catch (error) {
                setError(`Ошибка обновления поля: ${fieldName}. Пожалуйста, попробуйте позже.`);
            }
        }
    };

    return (
        <Container>
            <Typography variant="h4" sx={{ fontWeight: 800, color: theme.palette.primary.main, marginBottom: '20px' }}>
                Пользовательские документы
            </Typography>

            {error && <Alert severity="error">{error}</Alert>}

            <List>
                {documents ? (
                    <>
                        <ListItem>
                            <ListItemText
                                primary="Политика конфиденциальности"
                                secondary={
                                    documents.privacyPolicyUrl ? (
                                        <a href={documents.privacyPolicyUrl} target="_blank" rel="noopener noreferrer">
                                            {documents.privacyPolicyUrl}
                                        </a>
                                    ) : (
                                        'Документ не загружен'
                                    )
                                }
                            />
                            <input
                                accept=".pdf"
                                style={{ display: 'none' }}
                                id="privacyPolicyUpload"
                                type="file"
                                onChange={(e) => handleFileChange(e, 'privacyPolicyUrl')}
                            />
                            <label htmlFor="privacyPolicyUpload">
                                <Button variant="contained" component="span" sx={{ fontWeight: 800 }}>
                                    {documents.privacyPolicyUrl ? 'Обновить' : 'Загрузить'}
                                </Button>
                            </label>
                        </ListItem>

                        <ListItem>
                            <ListItemText
                                primary="Условия обслуживания"
                                secondary={
                                    documents.termsOfServiceUrl ? (
                                        <a href={documents.termsOfServiceUrl} target="_blank" rel="noopener noreferrer">
                                            {documents.termsOfServiceUrl}
                                        </a>
                                    ) : (
                                        'Документ не загружен'
                                    )
                                }
                            />
                            <input
                                accept=".pdf"
                                style={{ display: 'none' }}
                                id="termsOfServiceUpload"
                                type="file"
                                onChange={(e) => handleFileChange(e, 'termsOfServiceUrl')}
                            />
                            <label htmlFor="termsOfServiceUpload">
                                <Button variant="contained" component="span" sx={{ fontWeight: 800 }}>
                                    {documents.termsOfServiceUrl ? 'Обновить' : 'Загрузить'}
                                </Button>
                            </label>
                        </ListItem>

                        <ListItem>
                            <ListItemText
                                primary="Пользовательское соглашение"
                                secondary={
                                    documents.userAgreementUrl ? (
                                        <a href={documents.userAgreementUrl} target="_blank" rel="noopener noreferrer">
                                            {documents.userAgreementUrl}
                                        </a>
                                    ) : (
                                        'Документ не загружен'
                                    )
                                }
                            />
                            <input
                                accept=".pdf"
                                style={{ display: 'none' }}
                                id="userAgreementUpload"
                                type="file"
                                onChange={(e) => handleFileChange(e, 'userAgreementUrl')}
                            />
                            <label htmlFor="userAgreementUpload">
                                <Button variant="contained" component="span" sx={{ fontWeight: 800 }}>
                                    {documents.userAgreementUrl ? 'Обновить' : 'Загрузить'}
                                </Button>
                            </label>
                        </ListItem>

                        <ListItem>
                            <ListItemText
                                primary="Политика обработки данных"
                                secondary={
                                    documents.dataProcessingPolicyUrl ? (
                                        <a href={documents.dataProcessingPolicyUrl} target="_blank" rel="noopener noreferrer">
                                            {documents.dataProcessingPolicyUrl}
                                        </a>
                                    ) : (
                                        'Документ не загружен'
                                    )
                                }
                            />
                            <input
                                accept=".pdf"
                                style={{ display: 'none' }}
                                id="dataProcessingPolicyUpload"
                                type="file"
                                onChange={(e) => handleFileChange(e, 'dataProcessingPolicyUrl')}
                            />
                            <label htmlFor="dataProcessingPolicyUpload">
                                <Button variant="contained" component="span" sx={{ fontWeight: 800 }}>
                                    {documents.dataProcessingPolicyUrl ? 'Обновить' : 'Загрузить'}
                                </Button>
                            </label>
                        </ListItem>
                    </>
                ) : (
                    <Typography>Загрузка документов...</Typography>
                )}
            </List>
        </Container>
    );
};

export default DocumentsComponent;