import api from 'axios';
import {apiUrl, isNeedToSecure} from "../App";
import {UserData} from "../types/user/interfaces";

export async function checkAuthentication(): Promise<boolean> {
    if (isNeedToSecure === "false") {
        return true;
    }

    try {
        const response = await api.get(`${apiUrl}/auth/token/admin/validate`);

        if (response.status === 200) {
            // await fetchUserData();
            return true;
        } else if (response.status === 403) {
            return false;
        } else {
            console.error('Authentication failed with status:', response.statusText);
            return false;
        }
    } catch (error) {
        console.error('Error during authentication check:', error);
        return false;
    }
}

async function fetchUserData() {
    try {
        const userResponse = await api.get(`${apiUrl}/auth/token/get-info`);

        if (userResponse.status === 200) {
            const userData: UserData = userResponse.data;
            localStorage.setItem('userData', JSON.stringify(userData));
        } else {
            throw new Error(`Failed to fetch user data: ${userResponse.status}`);
        }
    } catch (error) {
        console.error('Error fetching user data:', error);
    }
}
