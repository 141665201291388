import React from 'react';
import { Drawer, List, ListItem } from '@mui/material';
import {useTheme} from "@mui/material/styles";
import {useActiveMenuItem} from "../../hooks/useActiveMenuItem";
import {menuItems} from "../../types/menuItems";
import MenuItemComponent from "./MenuItemComponent";

interface SidebarProps {
    width: number;
    open: boolean;
    onClose: () => void;
    isMobile: boolean;
}

const LeftNavigationBar: React.FC<SidebarProps> = ({ width, open, onClose, isMobile }) => {
    const theme = useTheme();
    const activePath = useActiveMenuItem();

    return (
        <Drawer
            variant={isMobile ? 'temporary' : 'permanent'}
            open={open}
            onClose={onClose}
            sx={{
                width: width,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { boxSizing: 'border-box', backgroundColor: theme.palette.primary.main, borderRight: 'none'},
            }}
        >
            <List sx={{
                '& .MuiListItem-root': {
                    color: theme.palette.text.primary,
                },
                '& .MuiListItemIcon-root': {
                    color: theme.palette.text.primary,
                },
                width: width,
            }}>
                <ListItem sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src="/knopka_main.png" alt="Logo" style={{width: '175px'}}/>
                </ListItem>
                {menuItems.map((item, index) => (
                    <MenuItemComponent key={index} {...item} active={item.path === activePath} theme={theme} />
                ))}
            </List>
        </Drawer>
    );
};

export default LeftNavigationBar;
