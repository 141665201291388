import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Grid } from '@mui/material';

interface AddPositionDialogProps {
    open: boolean;
    productId: string;
    onClose: () => void;
    onAddPosition: (size: string, quantity: number) => void;
}

const AddPositionDialog: React.FC<AddPositionDialogProps> = ({ open, productId, onClose, onAddPosition }) => {
    const [size, setSize] = useState('');
    const [quantity, setQuantity] = useState<number>(1);

    const handleSave = () => {
        onAddPosition(size, quantity);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Добавить позицию для товара ID: {productId}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            label="Размер"
                            fullWidth
                            value={size}
                            onChange={(e) => setSize(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Количество"
                            type="number"
                            fullWidth
                            value={quantity}
                            onChange={(e) => setQuantity(Number(e.target.value))}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Отмена</Button>
                <Button onClick={handleSave} variant="contained" color="primary">
                    Добавить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddPositionDialog;
