import axios from "axios";
import {apiUrl} from "../App";

export enum PassportRequestStatus {
    PENDING = "PENDING",
    APPROVED = "APPROVED",
    REJECTED = "REJECTED"
}

export enum PassportRequestConfirmationType {
    ORDER_CONFIRMATION = "ORDER_CONFIRMATION",
    USER_CONFIRMATION = "USER_CONFIRMATION"
}

export interface BasicConfirmation {
    type: PassportRequestConfirmationType;
}

export interface OrderConfirmation extends BasicConfirmation {
    orderId: string;
}

export interface UserConfirmation extends BasicConfirmation {
    userId: string;
}

export interface PassportMediaInfoKeys {
    firstPhotoKey: string;
    registrationPhotoKey: string;
    selfiePhotoKey: string;
}

export interface PassportRequestDTO {
    id: string;
    dateOfCreation: string;
    status: PassportRequestStatus;
    passportMediaInfoKeys: PassportMediaInfoKeys;
    confirmation: OrderConfirmation | UserConfirmation;
}

export interface PassportMediaInfoSecureUrlsDTO {
    firstPhotoSecureUrl: string;
    registrationPhotoSecureUrl: string;
    selfiePhotoSecureUrl: string;
}

class PassportService {
    async getAllPassportRequests(): Promise<PassportRequestDTO[]> {
        try {
            const response = await axios.get(`${apiUrl}/passport/request/get-all`);
            return response.data as PassportRequestDTO[];
        } catch (error) {
            console.error('Error fetching passport requests', error);
            throw error;
        }
    }

    async getPassportMediaUrls(mediaInfoKeys: PassportMediaInfoKeys): Promise<PassportMediaInfoSecureUrlsDTO> {
        try {
            const response = await axios.post(`${apiUrl}/media/passport/get-photo`, mediaInfoKeys);
            return response.data as PassportMediaInfoSecureUrlsDTO;
        } catch (error) {
            console.error('Error fetching passport media URLs', error);
            throw error;
        }
    }

    async submitPassportDecision(decisionDTO: any): Promise<void> {
        try {
            await axios.post(`${apiUrl}/passport/request/decision`, decisionDTO);
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                throw new Error(error.response.data || 'Ошибка при принятии заявки');
            } else {
                throw new Error('Ошибка при принятии заявки');
            }
        }
    }

    async rejectPassportRequest(rejectionDTO: any): Promise<void> {
        try {
            await axios.post(`${apiUrl}/passport/request/reject`, rejectionDTO);
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                throw new Error(error.response.data || 'Ошибка при отклонении заявки');
            } else {
                throw new Error('Ошибка при отклонении заявки');
            }
        }
    }
}

export default new PassportService();