import axios from "axios";
import {apiUrl} from "../App";

export enum OrderStatus {
    VERIFYING = "VERIFYING",
    PREPARE = "PREPARE",
    DELIVERY_REGISTERED = "DELIVERY_REGISTERED",
    DELIVERY_COMPLETED = "DELIVERY_COMPLETED",
    IN_RENT = "IN_RENT",
    RENT_COMPLETED = "RENT_COMPLETED",
    RETURN_REGISTERED = "RETURN_REGISTERED",
    RETURNED = "RETURNED",
    CANCELED_PRE_DELIVERY = "CANCELED_PRE_DELIVERY",
    CANCELED_PRE_DELIVERY_NOT_PAID = "CANCELED_PRE_DELIVERY_NOT_PAID",
    CANCELED_PRE_DELIVERY_AND_REFUNDED = "CANCELED_PRE_DELIVERY_AND_REFUNDED",
    CANCELED_DURING_RENT = "CANCELED_DURING_RENT",
    CANCELED_BEFORE_RENT = "CANCELED_BEFORE_RENT",
    RETURNED_AFTER_CANCELLATION = "RETURNED_AFTER_CANCELLATION",
    RETURN_REGISTERED_AFTER_CANCELLATION = "RETURN_REGISTERED_AFTER_CANCELLATION",
    CANCELED_DURING_DELIVERY = "CANCELED_DURING_DELIVERY"
}

export const OrderStatusText: { [key in OrderStatus]: string } = {
    [OrderStatus.VERIFYING]: "На проверке",
    [OrderStatus.PREPARE]: "Подготовка",
    [OrderStatus.DELIVERY_REGISTERED]: "Доставка зарегистрирована",
    [OrderStatus.DELIVERY_COMPLETED]: "Доставка завершена",
    [OrderStatus.IN_RENT]: "В аренде",
    [OrderStatus.RENT_COMPLETED]: "Аренда завершена",
    [OrderStatus.RETURN_REGISTERED]: "Возврат зарегистрирован",
    [OrderStatus.RETURNED]: "Возвращено",
    [OrderStatus.CANCELED_PRE_DELIVERY]: "Отменено до доставки",
    [OrderStatus.CANCELED_PRE_DELIVERY_NOT_PAID]: "Отменено до доставки (не оплачено)",
    [OrderStatus.CANCELED_PRE_DELIVERY_AND_REFUNDED]: "Отменено до доставки и возвращено",
    [OrderStatus.CANCELED_DURING_RENT]: "Отменено во время аренды",
    [OrderStatus.CANCELED_BEFORE_RENT]: "Отменено до аренды",
    [OrderStatus.RETURNED_AFTER_CANCELLATION]: "Возвращено после отмены",
    [OrderStatus.RETURN_REGISTERED_AFTER_CANCELLATION]: "Возврат зарегистрирован после отмены",
    [OrderStatus.CANCELED_DURING_DELIVERY]: "Отменено во время доставки (не доставлено)"
};


export interface OrderStatusHistory {
    status: OrderStatus;
    changedAt: string;
}

export interface Order {
    id: string;
    items: { productId: string; itemPrice: number }[];
    description?: string;
    price: number;
    currentStatus: OrderStatus;
    isPassportInfoExists: boolean;
    fromDate: string;
    toDate: string;
    yooKassaPaymentUrl: string;
    statusHistory: OrderStatusHistory[];
    dateOfDelivery?: string | null;
    dateOfReturn?: string | null;
    deliveryAddress: string;
    returnAddress?: string;
    contactEmail: string;
    contactPhone: string;
    firstName: string;
    secondName: string;
    patronymic?: string;
}

class OrderService {

    static async getAllOrders(): Promise<Order[]> {
        try {
            const response = await axios.get(`${apiUrl}/order/all`);
            return response.data;
        } catch (error) {
            console.error('Error fetching news items', error);
            throw error;
        }
    }

    static async changeDeliveryDateAndAddress(orderId: string, date: string, address?: string): Promise<void> {
        try {
            const reqUrl = `${apiUrl}/order/${orderId}/register-delivery`;

            const params: Record<string, string> = {
                deliveryDate: date,
            };

            if (address) {
                params.newDeliveryAddress = address;
            }

            await axios.patch(reqUrl, null, { params });
        } catch (error) {
            console.error('Ошибка при изменении даты доставки и адреса:', error);
            throw error;
        }
    }

    static async changeReturnDateAndReturnAddress(orderId: string, date: string, address?: string): Promise<void> {
        try {
            const reqUrl = `${apiUrl}/order/${orderId}/register-return`;

            const params: Record<string, string> = {
                returnDate: date,
            };

            if (address) {
                params.newDeliveryAddress = address;
            }

            await axios.patch(reqUrl, null, { params });
        } catch (error) {
            console.error('Ошибка при изменении даты возврата и адреса:', error);
            throw error;
        }
    }

    static async updateOrderStatusToDelivered(orderId: string, success: boolean): Promise<void> {
        try {
            const reqUrl = `${apiUrl}/order/${orderId}/mark-status-delivery`;

            const params: Record<string, string | boolean> = {
                success: success,
            };

            await axios.patch(reqUrl, null, { params });
        } catch (error) {
            console.error('Ошибка при обновлении статуса доставки:', error);
            throw error;
        }
    }

    static async updateOrderStatusToReturned(orderId: string): Promise<void> {
        try {
            const reqUrl = `${apiUrl}/order/${orderId}/mark-as-returned`;

            await axios.patch(reqUrl);
        } catch (error) {
            console.error('Ошибка при обновлении статуса возврата:', error);
            throw error;
        }
    }

}

export default OrderService;