import { useState, useEffect } from 'react';
import { Outlet, Navigate, useNavigate } from 'react-router-dom';
import {checkAuthentication} from "../AuthChecker";

const PrivateRoutes = () => {
    const [isAuth, setIsAuth] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const authenticate = async () => {
            const authenticated = await checkAuthentication();
            if (!authenticated) {
                navigate('/auth');
            }
            setIsAuth(authenticated);
            setIsLoading(false);
        };

        authenticate();
    }, []);

    if (isLoading) {
        return null;
    }

    return (
        isAuth ? <Outlet /> : <Navigate to="/auth" />
    );
}

export default PrivateRoutes;
