import axios from 'axios';
import {apiUrl} from "../App";

export interface PromoCode {
    value: string;
    promoCodeId: string;
    expirationDate: string;
    used: boolean;
}

export interface ShortUserInfoDTO {
    id: string;
    firstName: string;
    secondName: string;
    requestId: string;
    promoCodes: PromoCode[];
    passStatus: 'REJECTED' | 'PENDING' | 'APPROVED';
}

class UserService {
    async getShortUserInfos(): Promise<ShortUserInfoDTO[]> {
        try {
            const response = await axios.get(`${apiUrl}/user/short-user-infos`);
            return response.data as ShortUserInfoDTO[];
        } catch (error) {
            console.error('Error fetching short user infos', error);
            throw error;
        }
    }

    async createPromoCodes(userIds: string[], expirationDate: string, value: string): Promise<void> {
        try {
            await axios.post(`${apiUrl}/user/promo/create`, userIds, {
                params: {
                    expirationDate,
                    value,
                },
            });
        } catch (error) {
            console.error('Error creating promo codes', error);
            throw error;
        }
    }
}

export default new UserService();