import React, { useState, useEffect } from 'react';
import { Box, Button, Container, TextField, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AuthService from "../logic/AuthService";
import {checkAuthentication} from "../auth/AuthChecker";

const AuthPage: React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        const authenticate = async () => {
            const isAuthenticated = await checkAuthentication();
            if (isAuthenticated) {
                navigate('/admin/panel');
            }
        };

        authenticate();
    }, [navigate]);

    const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const isLoggedIn = await AuthService.processLogIn({ email: username, password });
        if (isLoggedIn) {
            navigate('/admin/panel');
        } else {
            // Handle login failure (e.g., show an error message)
        }
    };

    return (
        <Container maxWidth="xs">
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100vh"
                textAlign="center"
            >
                <img src="/knopka_main_blue.png" alt="Logo" style={{ width: '150px', marginBottom: '20px' }} />
                <Typography variant="h4" sx={{
                    fontWeight: 800,
                    color: theme.palette.primary.main
                }} gutterBottom>
                    Панель администратора
                </Typography>
                <Box component="form" onSubmit={handleLogin} sx={{ width: '100%', mt: 1 }}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Имя пользователя"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '&:hover fieldset': {
                                    borderColor: theme.palette.secondary.main,
                                }
                            }
                        }}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="password"
                        label="Пароль"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '&:hover fieldset': {
                                    borderColor: theme.palette.secondary.main,
                                }
                            }
                        }}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{
                            mt: 3,
                            mb: 2,
                            fontWeight: 800,
                            '&:hover': {
                                backgroundColor: theme.palette.secondary.main,
                                color: theme.palette.common.white,
                            }
                        }}
                    >
                        Войти
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default AuthPage;