import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, Grid, Snackbar, Alert } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { arrayMoveImmutable } from 'array-move';
import MediaCard from './MediaCard';

interface AddMediaDialogProps {
    open: boolean;
    productId: string;
    onClose: () => void;
    existingMediaKeys: (string | null)[];
    updateProductMedia: (
        productId: string,
        firstMedia: File | null, secondMedia: File | null, thirdMedia: File | null,
        fourthMedia: File | null, fifthMedia: File | null, sixthMedia: File | null,
        firstMediaKey: string | null, secondMediaKey: string | null, thirdMediaKey: string | null,
        fourthMediaKey: string | null, fifthMediaKey: string | null, sixthMediaKey: string | null
    ) => Promise<void>;
}

const AddMediaDialog: React.FC<AddMediaDialogProps> = ({ open, productId, onClose, existingMediaKeys, updateProductMedia }) => {
    const [files, setFiles] = useState<(File | null)[]>(new Array(6).fill(null));
    const [keys, setKeys] = useState<(string | null)[]>(existingMediaKeys);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const updatedFiles = [...files];
        existingMediaKeys.forEach((key, index) => {
            if (key && !updatedFiles[index]) {
                updatedFiles[index] = null;
            }
        });
        setKeys(existingMediaKeys);
        setFiles(updatedFiles);
    }, [existingMediaKeys]);

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': [],
            'video/*': []
        },
        onDrop: (acceptedFiles) => {
            const newFiles = [...files];
            const newKeys = [...keys];

            acceptedFiles.forEach((file, idx) => {
                for (let i = 0; i < newFiles.length; i++) {
                    if (!newFiles[i] && !newKeys[i]) {
                        newFiles[i] = file;
                        newKeys[i] = null;
                        break;
                    }
                }
            });

            setFiles(newFiles);
            setKeys(newKeys);
        },
    });

    const handleRemove = (index: number) => {
        const newFiles = [...files];
        const newKeys = [...keys];

        newFiles[index] = null;
        newKeys[index] = null;

        setFiles(newFiles);
        setKeys(newKeys);
    };

    const handleMove = (index: number, direction: 'up' | 'down') => {
        const newIndex = direction === 'up' ? index - 1 : index + 1;
        if (newIndex < 0 || newIndex >= 6) return;

        const newFiles = arrayMoveImmutable(files, index, newIndex);
        const newKeys = arrayMoveImmutable(keys, index, newIndex);
        setFiles(newFiles);
        setKeys(newKeys);
    };

    const handleSave = async () => {
        try {
            await updateProductMedia(
                productId,
                files[0], files[1], files[2],
                files[3], files[4], files[5],
                keys[0], keys[1], keys[2],
                keys[3], keys[4], keys[5]
            );
            onClose();
        } catch (err) {
            setError('Не удалось сохранить медиафайлы. Пожалуйста, попробуйте еще раз.');
        }
    };

    const handleCloseSnackbar = () => {
        setError(null);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Добавить медиафайлы для товара ID: {productId}</DialogTitle>
            <DialogContent>
                <Box {...getRootProps()} border="1px dashed gray" padding={2} marginBottom={2}>
                    <input {...getInputProps()} />
                    <Typography align="center">Перетащите файлы сюда или нажмите для выбора</Typography>
                </Box>
                <Grid container spacing={2}>
                    {Array.from({ length: 6 }).map((_, index) => (
                        <Grid item xs={4} key={index}>
                            <MediaCard
                                file={files[index]}
                                objectKey={keys[index]}
                                index={index}
                                onMoveUp={() => handleMove(index, 'up')}
                                onMoveDown={() => handleMove(index, 'down')}
                                onRemove={() => handleRemove(index)}
                                isMain={index === 0}
                            />
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Отмена</Button>
                <Button onClick={handleSave} variant="contained" color="primary">
                    Сохранить
                </Button>
            </DialogActions>
            <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="error">
                    {error}
                </Alert>
            </Snackbar>
        </Dialog>
    );
};

export default AddMediaDialog;