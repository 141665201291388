import React, { useEffect, useMemo, useState } from 'react';
import {
    Box,
    Container,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Pagination,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    MenuItem,
    Alert
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Check } from "@mui/icons-material";
import PassportService, {
    PassportMediaInfoKeys,
    PassportMediaInfoSecureUrlsDTO,
    PassportRequestConfirmationType,
    PassportRequestDTO
} from "../../logic/PassportService";
import {useNavigate} from "react-router-dom";

const ITEMS_PER_PAGE = 5;

const getRequestTypeDescription = (requestType: PassportRequestConfirmationType) => {
    switch (requestType) {
        case PassportRequestConfirmationType.USER_CONFIRMATION:
            return "Подтверждение аккаунта";
        case PassportRequestConfirmationType.ORDER_CONFIRMATION:
            return "Подтверждение заказа";
        default:
            return "";
    }
}

const rejectionReasons = [
    "Не соответствует требованиям",
    "Документы нечитабельны",
    "Недействительные документы",
    "Другие причины"
];

const PassportComponent: React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const [requests, setRequests] = useState<PassportRequestDTO[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedRequest, setSelectedRequest] = useState<PassportRequestDTO | null>(null);
    const [actionType, setActionType] = useState<"accept" | "reject" | null>(null);
    const [rejectionReason, setRejectionReason] = useState('');
    const [customRejectionReason, setCustomRejectionReason] = useState('');
    const [mediaUrls, setMediaUrls] = useState<PassportMediaInfoSecureUrlsDTO | null>(null);
    const [formData, setFormData] = useState({
        fullName: '',
        passportSeries: '',
        passportNumber: '',
        issueDate: '',
        expirationDate: '',
        issuedBy: '',
        birthDate: '',
        placeOfBirth: '',
        registrationAddress: ''
    });
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchRequests = async () => {
            try {
                const allRequests = await PassportService.getAllPassportRequests();
                const pendingRequests = allRequests.filter(request => request.status === 'PENDING');
                setRequests(pendingRequests);
            } catch (error: any) {
                setError(error.message);
            }
        };

        fetchRequests();
    }, []);

    useEffect(() => {
        if (selectedRequest) {
            const fetchMediaUrls = async () => {
                try {
                    const mediaInfoKeys: PassportMediaInfoKeys = {
                        firstPhotoKey: selectedRequest.passportMediaInfoKeys.firstPhotoKey,
                        registrationPhotoKey: selectedRequest.passportMediaInfoKeys.registrationPhotoKey,
                        selfiePhotoKey: selectedRequest.passportMediaInfoKeys.selfiePhotoKey
                    };
                    const urls = await PassportService.getPassportMediaUrls(mediaInfoKeys);
                    setMediaUrls(urls);
                } catch (error: any) {
                    setError(error.message);
                }
            };
            fetchMediaUrls();
        }
    }, [selectedRequest]);

    const sortedRequests = useMemo(() => {
        return requests.sort((a, b) => new Date(a.dateOfCreation).getTime() - new Date(b.dateOfCreation).getTime());
    }, [requests]);

    const paginatedRequests = useMemo(() => {
        const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
        return sortedRequests.slice(startIndex, startIndex + ITEMS_PER_PAGE);
    }, [sortedRequests, currentPage]);

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page);
    };

    const handleCheckRequest = (request: PassportRequestDTO) => {
        setSelectedRequest(request);
    };

    const handleAction = (action: "accept" | "reject") => {
        setActionType(action);
    };

    const handleCloseDialog = () => {
        setSelectedRequest(null);
        setActionType(null);
        setRejectionReason('');
        setCustomRejectionReason('');
        setMediaUrls(null);
        setError(null);
        setFormData({
            fullName: '',
            passportSeries: '',
            passportNumber: '',
            issueDate: '',
            expirationDate: '',
            issuedBy: '',
            birthDate: '',
            placeOfBirth: '',
            registrationAddress: ''
        });
    };

    const handleRejectRequest = async () => {
        if (selectedRequest) {
            try {
                await PassportService.rejectPassportRequest({
                    requestId: selectedRequest.id,
                    rejectionReason: rejectionReason === 'custom' ? customRejectionReason : rejectionReason
                });
                handleCloseDialog();
                navigate(0);
            } catch (error: any) {
                setError(error.message);
            }
        }
    };

    const handleAcceptRequest = async () => {
        if (selectedRequest) {
            try {
                await PassportService.submitPassportDecision({
                    requestId: selectedRequest.id,
                    fullName: formData.fullName,
                    passportSeries: formData.passportSeries,
                    passportNumber: formData.passportNumber,
                    issueDate: formData.issueDate,
                    issuedBy: formData.issuedBy,
                    birthDate: formData.birthDate,
                    placeOfBirth: formData.placeOfBirth,
                    registrationAddress: formData.registrationAddress,
                });
                handleCloseDialog();
                navigate(0);
            } catch (error: any) {
                setError(error.message);
            }
        }
    };

    const handleFormDataChange = (field: string, value: string) => {
        setFormData(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    return (
        <Container>
            <Typography variant="h4" sx={{ fontWeight: 800, color: theme.palette.primary.main, marginBottom: '20px' }}>
                Заявки на подтверждение паспортных данных
            </Typography>

            {error && (
                <Alert severity="error" sx={{ marginBottom: '20px' }}>
                    {error}
                </Alert>
            )}

            <Box>
                <List>
                    {paginatedRequests.map((request) => (
                        <ListItem key={request.id}>
                            <ListItemText
                                primary={getRequestTypeDescription(request.confirmation.type) +
                                    (request.confirmation.type === PassportRequestConfirmationType.USER_CONFIRMATION && (request.confirmation as any).userId
                                        ? ` (ID пользователя: ${(request.confirmation as any).userId})`
                                        : '') +
                                    (request.confirmation.type === PassportRequestConfirmationType.ORDER_CONFIRMATION && (request.confirmation as any).orderId
                                        ? ` (ID заказа: ${(request.confirmation as any).orderId})`
                                        : '')}
                                secondary={`Дата отправки: ${new Date(request.dateOfCreation).toLocaleDateString()} ${new Date(request.dateOfCreation).toLocaleTimeString()}`}
                                sx={{ color: theme.palette.primary.main }}
                            />
                            <IconButton onClick={() => handleCheckRequest(request)}>
                                <Check />
                            </IconButton>
                        </ListItem>
                    ))}
                </List>
                <Pagination
                    count={Math.ceil(sortedRequests.length / ITEMS_PER_PAGE)}
                    page={currentPage}
                    onChange={handlePageChange}
                    sx={{
                        mt: 2,
                        display: 'flex',
                        justifyContent: 'center',
                        '& .MuiPaginationItem-root': {
                            '&.Mui-selected': {
                                backgroundColor: theme.palette.secondary.main,
                                color: 'white',
                            },
                            '&:not(.Mui-selected)': {
                                backgroundColor: theme.palette.primary.main,
                            },
                        },
                    }}
                />
            </Box>

            {selectedRequest && (
                <Dialog open={Boolean(selectedRequest)} onClose={handleCloseDialog}>
                    <DialogTitle>Проверка заявки</DialogTitle>
                    <DialogContent>
                        {/* Всегда показываем фотографии */}
                        {mediaUrls && (
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                <img src={mediaUrls.firstPhotoSecureUrl} alt="Лицевая сторона" />
                                <img src={mediaUrls.registrationPhotoSecureUrl} alt="Регистрация" />
                                <img src={mediaUrls.selfiePhotoSecureUrl} alt="Селфи" />
                            </Box>
                        )}

                        {/* Выбор действия */}
                        {!actionType && (
                            <Box sx={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '20px' }}>
                                <Button variant="contained" color="primary" onClick={() => handleAction("accept")}>
                                    Принять
                                </Button>
                                <Button variant="contained" color="error" onClick={() => handleAction("reject")}>
                                    Отклонить
                                </Button>
                            </Box>
                        )}

                        {/* Поля формы при принятии заявки */}
                        {actionType === "accept" && (
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '20px' }}>
                                <Typography variant="h6">Данные паспорта:</Typography>
                                <TextField
                                    label="ФИО"
                                    value={formData.fullName}
                                    onChange={(e) => handleFormDataChange('fullName', e.target.value)}
                                    fullWidth
                                />
                                <TextField
                                    label="Серия паспорта"
                                    value={formData.passportSeries}
                                    onChange={(e) => handleFormDataChange('passportSeries', e.target.value)}
                                    fullWidth
                                />
                                <TextField
                                    label="Номер паспорта"
                                    value={formData.passportNumber}
                                    onChange={(e) => handleFormDataChange('passportNumber', e.target.value)}
                                    fullWidth
                                />
                                <TextField
                                    label="Дата выдачи"
                                    type="date"  // изменено с datetime-local на date
                                    InputLabelProps={{ shrink: true }}
                                    value={formData.issueDate}
                                    onChange={(e) => handleFormDataChange('issueDate', e.target.value)}
                                    fullWidth
                                />
                                <TextField
                                    label="Кем выдан"
                                    value={formData.issuedBy}
                                    onChange={(e) => handleFormDataChange('issuedBy', e.target.value)}
                                    fullWidth
                                />
                                <TextField
                                    label="Дата рождения"
                                    type="date"  // изменено с datetime-local на date
                                    InputLabelProps={{ shrink: true }}
                                    value={formData.birthDate}
                                    onChange={(e) => handleFormDataChange('birthDate', e.target.value)}
                                    fullWidth
                                />
                                <TextField
                                    label="Место рождения"
                                    value={formData.placeOfBirth}
                                    onChange={(e) => handleFormDataChange('placeOfBirth', e.target.value)}
                                    fullWidth
                                />
                                <TextField
                                    label="Адрес регистрации"
                                    value={formData.registrationAddress}
                                    onChange={(e) => handleFormDataChange('registrationAddress', e.target.value)}
                                    fullWidth
                                />
                            </Box>
                        )}

                        {/* Поля для указания причины при отклонении заявки */}
                        {actionType === "reject" && (
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '20px' }}>
                                <Typography variant="h6">Причина отклонения:</Typography>
                                <TextField
                                    select
                                    label="Причина"
                                    value={rejectionReason}
                                    onChange={(e) => setRejectionReason(e.target.value)}
                                    fullWidth
                                >
                                    {rejectionReasons.map(reason => (
                                        <MenuItem key={reason} value={reason}>
                                            {reason}
                                        </MenuItem>
                                    ))}
                                    <MenuItem value="custom">Своя причина</MenuItem>
                                </TextField>
                                {rejectionReason === 'custom' && (
                                    <TextField
                                        label="Своя причина"
                                        value={customRejectionReason}
                                        onChange={(e) => setCustomRejectionReason(e.target.value)}
                                        fullWidth
                                    />
                                )}
                            </Box>
                        )}

                        {error && (
                            <Alert severity="error" sx={{ marginTop: '20px' }}>
                                {error}
                            </Alert>
                        )}
                    </DialogContent>
                    <DialogActions>
                        {actionType === "reject" && (
                            <Button onClick={handleRejectRequest} color="error">Отклонить</Button>
                        )}
                        {actionType === "accept" && (
                            <Button onClick={handleAcceptRequest} color="primary">Принять</Button>
                        )}
                        <Button onClick={handleCloseDialog} color="info">Закрыть</Button>
                    </DialogActions>
                </Dialog>
            )}
        </Container>
    );
}

export default PassportComponent;