import React, { useState, useEffect } from 'react';
import {
    Box, Button, Container, Typography, IconButton, Collapse, Paper, List, ListItem, ListItemText, ListItemSecondaryAction, Tooltip
} from "@mui/material";
import {ExpandMore, ExpandLess, Add, Delete, PhotoLibrary, Edit} from '@mui/icons-material';
import AddProductDialog from "./dialog/AddProductDialog";
import AddMediaDialog from "./dialog/AddMediaDialog";
import AddPositionDialog from "./dialog/AddPositionDialog";
import { Product } from "../../types/products/interfaces";
import ProductAndCategoryService from "../../logic/ProductAndCategoryService";
import {useTheme} from "@mui/material/styles";
import EditProductDialog from "./dialog/EditProductDialog";

const ProductComponent: React.FC = () => {
    const theme = useTheme()

    const [products, setProducts] = useState<Product[]>([]);
    const [open, setOpen] = useState<string | null>(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [mediaDialogOpen, setMediaDialogOpen] = useState<string | null>(null);
    const [positionDialogOpen, setPositionDialogOpen] = useState<string | null>(null);
    const [editDialogOpen, setEditDialogOpen] = useState<string | null>(null);
    const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

    const fetchProducts = async () => {
        try {
            const fetchedProducts = await ProductAndCategoryService.getAllProductsForAdmin();
            setProducts(fetchedProducts);
        } catch (error) {
            console.error("Ошибка при получении товаров:", error);
        }
    };

    useEffect(() => {
        fetchProducts();
    }, []);

    const handleAddPosition = async (productId: string, size: string, quantity: number) => {
        try {
            for (let i = 0; i < quantity; i++) {
                await ProductAndCategoryService.addProductPosition(productId, size);
            }
            fetchProducts();
        } catch (error) {
            alert('Не удалось добавить позицию.');
        }
    };

    const handleDeletePosition = async (productId: string, positionId: string) => {
        try {
            await ProductAndCategoryService.deleteProductPosition(productId, positionId);
            fetchProducts();
        } catch (error) {
            alert('Не удалось удалить позицию.');
        }
    };

    const handleEditProduct = async (updatedProduct: Product) => {
        try {
            await ProductAndCategoryService.updateProduct(updatedProduct);
            fetchProducts();
        } catch (error) {
            alert('Не удалось обновить товар.');
        }
    };

    const handleDeleteProduct = async (productId: string) => {
        try {
            const success = await ProductAndCategoryService.deleteProduct(productId);
            if (success) {
                fetchProducts();
            } else {
                alert('Не удалось удалить товар.');
            }
        } catch (error) {
            alert('Не удалось удалить товар.');
        }
    };

    const handleAddProduct = (newProduct: Product) => {
        fetchProducts();
        setDialogOpen(false);
    };

    const toggleOpen = (id: string) => {
        setOpen(open === id ? null : id);
    };


    return (
        <Container>
            <Typography variant="h4" sx={{ fontWeight: 800, color: theme.palette.primary.main, marginBottom: '20px' }}>
                Управление товарами
            </Typography>
            <Button
                variant="contained"
                color="primary"
                startIcon={<Add />}
                onClick={() => setDialogOpen(true)}
                sx={{ marginBottom: '20px', fontWeight: 800 }}
            >
                Добавить новый товар
            </Button>

            <Typography variant="h5" sx={{ fontWeight: 700, color: theme.palette.primary.main, marginBottom: '10px' }}>
                Все товары и их позиции
            </Typography>
            <Paper sx={{ maxHeight: '400px', overflowY: 'auto' }}>
                <List>
                    {products.map((product, index) => (
                        <React.Fragment key={product.id}>
                            <ListItem>
                                <ListItemText
                                    primary={`${index + 1}. ${product.name}`}
                                    secondary={
                                        <>
                                            <Typography component="span" variant="body2" color="text.primary"> Цена:</Typography> {product.price},
                                            <Typography component="span" variant="body2" color="text.primary">Категории:</Typography>
                                            {product.categories.map(cat => (
                                                <React.Fragment key={cat.name}>
                                                    <Typography component="span" variant="body2" color="text.primary">{cat.name}:</Typography> {cat.value}
                                                </React.Fragment>
                                            ))}
                                        </>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <Tooltip title="Развернуть / Свернуть">
                                        <IconButton edge="end" onClick={() => toggleOpen(product.id)}>
                                            {open === product.id ? <ExpandLess /> : <ExpandMore />}
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Добавить позицию">
                                        <IconButton edge="end" onClick={() => setPositionDialogOpen(product.id)}>
                                            <Add />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Изменить товар">
                                        <IconButton edge="end" onClick={() => {
                                            setSelectedProduct(product);
                                            setEditDialogOpen(product.id);
                                        }}>
                                            <Edit />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Добавить медиафайлы">
                                        <IconButton edge="end" onClick={() =>{
                                            setSelectedProduct(product);
                                            setMediaDialogOpen(product.id)}}>
                                            <PhotoLibrary />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Удалить товар">
                                        <IconButton edge="end" onClick={() => handleDeleteProduct(product.id)}>
                                            <Delete />
                                        </IconButton>
                                    </Tooltip>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Collapse in={open === product.id} timeout="auto" unmountOnExit>
                                <Box margin={2}>
                                    <Typography variant="h6" gutterBottom component="div">
                                        Позиции товара
                                    </Typography>
                                    <Box sx={{ maxHeight: '150px', overflowY: 'auto' }}>
                                        <List component="div" disablePadding>
                                            {product.positions.map((position, idx) => (
                                                <ListItem key={position.positionId} sx={{ pl: 4 }}>
                                                    <ListItemText
                                                        primary={`${idx + 1}. ID: ${position.positionId}`}
                                                        secondary={
                                                            <>
                                                                <Typography component="span" variant="body2" color="text.primary">Размер:</Typography> {position.size}
                                                            </>
                                                        }
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <Tooltip title="Удалить позицию">
                                                            <IconButton edge="end" onClick={() => handleDeletePosition(product.id, position.positionId)}>
                                                                <Delete />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Box>
                                </Box>
                            </Collapse>
                        </React.Fragment>
                    ))}
                </List>
            </Paper>
            <AddProductDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                onAddProduct={handleAddProduct}
            />
            <EditProductDialog
                open={!!editDialogOpen}
                product={selectedProduct!}
                onClose={() => setEditDialogOpen(null)}
                onSave={handleEditProduct}
            />
            {mediaDialogOpen !== null && (
                <AddMediaDialog
                    open={!!mediaDialogOpen}
                    productId={mediaDialogOpen}
                    existingMediaKeys={selectedProduct?.mediaObjectKeys || []}
                    onClose={() => setMediaDialogOpen(null)}
                    updateProductMedia={async (...args) => {
                        await ProductAndCategoryService.updateProductMedia(...args);
                        await fetchProducts();
                    }}
                />
            )}
            {positionDialogOpen !== null && (
                <AddPositionDialog
                    open={!!positionDialogOpen}
                    productId={positionDialogOpen}
                    onClose={() => setPositionDialogOpen(null)}
                    onAddPosition={(size, quantity) => handleAddPosition(positionDialogOpen!, size, quantity)}
                />
            )}
        </Container>
    );
}

export default ProductComponent;