import React, { useEffect, useState } from 'react';
import { Card, CardMedia, CardContent, CardActions, IconButton, Typography } from '@mui/material';
import { ArrowUpward, ArrowDownward, Delete } from '@mui/icons-material';

interface MediaCardProps {
    file: File | null;
    objectKey: string | null;
    index: number;
    onMoveUp: () => void;
    onMoveDown: () => void;
    onRemove: () => void;
    isMain: boolean;
}

const MediaCard: React.FC<MediaCardProps> = ({ file, objectKey, index, onMoveUp, onMoveDown, onRemove, isMain }) => {
    const [showMedia, setShowMedia] = useState(true);
    const isEmpty = !file && !objectKey;

    const isImage = file
        ? file.type.startsWith('image')
        : objectKey && /\.(jpg|jpeg|png|gif)$/.test(objectKey || '');

    const isVideo = file
        ? file.type.startsWith('video')
        : objectKey && /\.(mp4|webm|ogg)$/.test(objectKey || '');

    const fileURL = file ? URL.createObjectURL(file) : objectKey ? `https://storage.yandexcloud.net/product-media-storage-prod/${objectKey}` : '';

    useEffect(() => {
        // console.log(`MediaCard ${index}:`);
        // console.log(`  file: ${file}`);
        // console.log(`  objectKey: ${objectKey}`);
        // console.log(`  isEmpty: ${isEmpty}`);
        // console.log(`  isImage: ${isImage}`);
        // console.log(`  isVideo: ${isVideo}`);
        // console.log(`  fileURL: ${fileURL}`);
    }, [file, objectKey, index]);

    const handleMediaError = () => {
        setShowMedia(false);
    };

    return (
        showMedia ? (
            <Card>
                {!isEmpty && (
                    <>
                        {isImage && (
                            <CardMedia
                                component="img"
                                image={fileURL}
                                alt={`media-${index}`}
                                height="140"
                                onError={handleMediaError}
                            />
                        )}
                        {isVideo && (
                            <CardMedia
                                component="video"
                                src={fileURL}
                                controls
                                height="140"
                                onError={handleMediaError}
                            />
                        )}
                        <CardContent>
                            <Typography variant="subtitle1">{file ? file.name : objectKey}</Typography>
                            {isMain && (
                                <Typography variant="caption" color="primary">
                                    Главное изображение
                                </Typography>
                            )}
                        </CardContent>
                    </>
                )}
                {isEmpty && (
                    <CardContent>
                        <Typography variant="body2" color="text.secondary" align="center">
                            Пустая карточка
                        </Typography>
                    </CardContent>
                )}
                <CardActions disableSpacing>
                    <IconButton onClick={onMoveUp} disabled={index === 0}>
                        <ArrowUpward />
                    </IconButton>
                    <IconButton onClick={onMoveDown} disabled={index === 5}>
                        <ArrowDownward />
                    </IconButton>
                    <IconButton onClick={onRemove}>
                        <Delete />
                    </IconButton>
                </CardActions>
            </Card>
        ) : null
    );
};

export default MediaCard;