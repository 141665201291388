import React, { useEffect, useState } from 'react';
import {
    Box, Button, Container, Typography, Paper, List, ListItem, ListItemText, Checkbox, IconButton, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Grid, Tooltip, InputAdornment, Chip, Alert
} from "@mui/material";
import { ExpandMore, ExpandLess, Search } from '@mui/icons-material';
import { useTheme } from "@mui/material/styles";
import UserService, { ShortUserInfoDTO } from "../../logic/UserService";

const PromoComponent: React.FC = () => {
    const theme = useTheme();

    const [users, setUsers] = useState<ShortUserInfoDTO[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<ShortUserInfoDTO[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
    const [promoDialogOpen, setPromoDialogOpen] = useState(false);
    const [registrationPromo, setRegistrationPromo] = useState<string>('');
    const [promoCodeValue, setPromoCodeValue] = useState<string>(''); // Добавляем состояние для значения промокода
    const [expirationDate, setExpirationDate] = useState<string>(''); // Добавляем состояние для даты истечения промокода
    const [openUserId, setOpenUserId] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetchUsers = async () => {
        try {
            const userData = await UserService.getShortUserInfos();
            setUsers(userData);
            setFilteredUsers(userData); // Инициализация отфильтрованных пользователей
        } catch (error) {
            setError('Ошибка при загрузке данных пользователей');
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
        if (e.target.value) {
            setFilteredUsers(users.filter(user => user.requestId.includes(e.target.value)));
        } else {
            setFilteredUsers(users);
        }
    };

    const handleToggleUser = (userId: string) => {
        setSelectedUsers(prevSelected =>
            prevSelected.includes(userId)
                ? prevSelected.filter(id => id !== userId)
                : [...prevSelected, userId]
        );
    };

    const handleSelectAll = () => {
        if (selectedUsers.length === filteredUsers.length) {
            setSelectedUsers([]);
        } else {
            setSelectedUsers(filteredUsers.map(user => user.id));
        }
    };

    const handlePromoCodeIssuance = () => {
        UserService.createPromoCodes(selectedUsers, expirationDate, promoCodeValue)
            .then(() => {
                setPromoDialogOpen(false);
                fetchUsers();
            })
            .catch((error) => {
                console.error('Error creating promo codes:', error);
                setError('Ошибка при создании промокодов');
            });
    };

    const toggleOpenUser = (userId: string) => {
        setOpenUserId(openUserId === userId ? null : userId);
    };

    const renderPassportStatus = (status: 'APPROVED' | 'PENDING' | 'REJECTED') => {
        let color: string;
        let label: string;

        switch (status) {
            case 'APPROVED':
                color = theme.palette.success.main;
                label = 'Паспорт подтвержден';
                break;
            case 'PENDING':
                color = theme.palette.warning.main;
                label = 'Паспорт в процессе';
                break;
            case 'REJECTED':
                color = theme.palette.error.main;
                label = 'Паспорт не подтвержден';
                break;
            default:
                return null;
        }

        return (
            <Chip
                label={label}
                sx={{
                    backgroundColor: color,
                    color: 'white',
                    fontWeight: 'bold',
                }}
            />
        );
    };

    return (
        <Container>
            <Typography variant="h4" sx={{ fontWeight: 800, marginBottom: '20px', color: theme.palette.primary.main }}>
                Список пользователей и промокодов
            </Typography>

            {error && <Alert severity="error" sx={{ mb: 1 }}>{error}</Alert>}

            {/*<Box sx={{ marginBottom: '20px' }}>*/}
            {/*    <Grid item xs={6}>*/}
            {/*        <Box display="flex" flexDirection="column" alignItems="flex-start">*/}
            {/*            <TextField*/}
            {/*                label="Процент промокода при регистрации"*/}
            {/*                value={registrationPromo}*/}
            {/*                onChange={(e) => setRegistrationPromo(e.target.value)}*/}
            {/*                variant="outlined"*/}
            {/*                sx={{ width: '250px', marginBottom: '10px' }}*/}
            {/*                InputProps={{*/}
            {/*                    endAdornment: <InputAdornment position="end">%</InputAdornment>,*/}
            {/*                }}*/}
            {/*            />*/}
            {/*            <Button*/}
            {/*                variant="contained"*/}
            {/*                color="primary"*/}
            {/*                sx={{ fontWeight: 700, width: '250px' }}*/}
            {/*                onClick={handleRegistrationPromoChange}*/}
            {/*            >*/}
            {/*                Сохранить значение*/}
            {/*            </Button>*/}
            {/*        </Box>*/}
            {/*    </Grid>*/}
            {/*</Box>*/}

            <Grid container spacing={2} alignItems="center" sx={{ marginBottom: '20px' }}>
                <Grid item xs={6}>
                    <TextField
                        label="Поиск по Request ID"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        variant="outlined"
                        fullWidth
                        inputProps={{
                            maxLength: 14,
                            pattern: "[A-Z0-9]{14}",
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={6} display="flex" justifyContent="flex-end">
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ fontWeight: 700 }}
                        onClick={() => setPromoDialogOpen(true)}
                        disabled={selectedUsers.length === 0}
                    >
                        Выдать промокод
                    </Button>
                </Grid>
            </Grid>

            <Paper sx={{ maxHeight: '400px', overflowY: 'auto', marginBottom: '20px', boxShadow: 'none', border: '1px solid #e0e0e0' }}>
                <List>
                    <ListItem
                        secondaryAction={
                            <Checkbox
                                edge="end"
                                onClick={handleSelectAll}
                                checked={selectedUsers.length === filteredUsers.length && filteredUsers.length > 0}
                                indeterminate={selectedUsers.length > 0 && selectedUsers.length < filteredUsers.length}
                                sx={{ color: 'primary.main', borderRadius: '50%' }}
                            />
                        }
                    >
                        <ListItemText primary="Выбрать всех" />
                    </ListItem>
                    {filteredUsers.map(user => (
                        <React.Fragment key={user.id}>
                            <ListItem>
                                <Checkbox
                                    edge="start"
                                    checked={selectedUsers.includes(user.id)}
                                    onClick={() => handleToggleUser(user.id)}
                                    sx={{ color: 'primary.main', borderRadius: '50%' }}
                                />
                                <ListItemText
                                    primary={`Request ID: ${user.requestId}`}
                                    secondary={
                                        <>
                                            <Typography variant="body2">{`Указанная Фамилия Имя: ${user.firstName} ${user.secondName}`}</Typography>
                                            {renderPassportStatus(user.passStatus)}
                                        </>
                                    }
                                />
                                <IconButton onClick={() => toggleOpenUser(user.id)}>
                                    {openUserId === user.id ? <ExpandLess /> : <ExpandMore />}
                                </IconButton>
                            </ListItem>
                            <Collapse in={openUserId === user.id} timeout="auto" unmountOnExit>
                                <Box margin={2}>
                                    <Typography variant="h6" gutterBottom>
                                        Промокоды пользователя
                                    </Typography>
                                    {user.promoCodes.length > 0 ? (
                                        <List component="div" disablePadding>
                                            {user.promoCodes.map((promo, idx) => (
                                                <ListItem key={promo.promoCodeId} sx={{ pl: 4 }}>
                                                    <ListItemText
                                                        primary={`${idx + 1}. Промокод: ${promo.promoCodeId} на ${promo.value}%`}
                                                        secondary={`Дата истечения: ${promo.expirationDate} Использован: ${promo.used ? 'да' : 'нет'}`}
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    ) : (
                                        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100px">
                                            <Typography variant="body1" color="textSecondary">
                                                Промокоды отсутствуют
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                            </Collapse>
                        </React.Fragment>
                    ))}
                </List>
            </Paper>

            <Dialog open={promoDialogOpen} onClose={() => setPromoDialogOpen(false)}>
                <DialogTitle>Выдать промокод</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Введите значение промокода для выбранных пользователей.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Промокод"
                        fullWidth
                        variant="outlined"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        value={promoCodeValue}
                        onChange={(e) => setPromoCodeValue(e.target.value)}
                    />
                    <TextField
                        label="Дата истечения"
                        type="date"
                        value={expirationDate}
                        onChange={(e) => setExpirationDate(e.target.value)}
                        fullWidth
                        sx={{ marginTop: 2 }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setPromoDialogOpen(false)} color="primary">
                        Отмена
                    </Button>
                    <Button
                        onClick={handlePromoCodeIssuance}
                        color="primary"
                    >
                        Выдать
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default PromoComponent;